import {
  ADD_ITEM_TO_BASKET,
  ADD_SET_MEAL_TO_BASKET,
  REMOVE_ITEM_FROM_BASKET,
  UPDATE_DELIVERY_COST,
  REMOVE_SET_MEAL_FROM_BASKET,
  UPDATE_SET_MEAL_IN_BASKET,
  POPULATE_BASKET_FROM_SERVER,
  UPDATE_DELIVERY_COLLECTION_STATE,
  SET_UPDATING_BASKET,
  GET_BASKET_FROM_SERVER,
  POPULATE_REORDER_DATA,
  CLEAR_REORDER_BASKET,
  APPLY_KUKD_POINTS_TO_BASKET,
  SET_BASKET_LIST,
  setBasketList,
  populateBasketFromServer,
  dispatchError,
  clearError,
  setApplyingKukdPoints,
  SET_PAYMENT_TYPE,
  APPLY_VOUCHER_CODE_TO_BASKET,
  REMOVE_VOUCHER_CODE_FROM_BASKET,
  APPLY_MANUAL_PROMO_TO_BASKET,
  REMOVE_PROMO_FROM_BASKET,
  RESET_RESTAURANT_BASKET,
  DISPLAY_FULLSCREEN_BASKET,
  SEND_SELECTED_ADDRESS_TO_SERVER,
  UPDATE_FEED_A_CHILD_CHARGE,
  SET_DELIVERY_ADDRESS,
  logoutUser
} from "../actions";
import API from "../api";
import Utils from "../utils/Utils";
import KukdError from "../KukdError";
import Cookies from "js-cookie";

const initialBasketData = {
  uuid: null,
  restaurantID: null,
  items: [],
  setMeals: [],
  itemsSubTotal: 0,
  setMealsSubTotal: 0,
  deliveryCollectionState: {
    deliverySelected: false,
    collectionSelected: false
  },
  deliveryAddress: { id: null, isValid: false },
  deliveryCost: -1,
  serviceCharge: 0,
  bagCharge: 0,
  is_paid: 0,
  kukdPointsToSpend: 0,
  appliedPromos: [],
  appliedVoucher: null,
  value: 0,
  updatingBasket: false,
  displayFullscreenBasket: false,
  feedAChildCharge: 0,
  feedAChildOptIn: 0,
  basketList: [],
  selectedTimeslot: {},
	chosenPaymentType: 0
};

const basketData = (state = initialBasketData, action) => {
  switch (action.type) {
    case SET_BASKET_LIST:
      return {
        ...state,
        basketList: action.baskets
      }

    case SET_DELIVERY_ADDRESS:
      return {
        ...state,
        deliveryAddress: action.address
      };
    case GET_BASKET_FROM_SERVER:
      API.getBasket(
        action.req,
        action.basketUUID,
        action.restaurantID,
        action.storeDispatch
      ).then(res => { });
      return state;

    case RESET_RESTAURANT_BASKET:
      return {
        ...initialBasketData,
        restaurantID: action.restaurantID
      };

    case POPULATE_BASKET_FROM_SERVER:
      // CHECK IF THE RETRIEVED UNPAID BASKET IS FROM A DISALLOWED SOURCE

      if (!action.serverBasket && action.basket) {
				action.serverBasket = action.basket
			}      
      if (
        !action.serverBasket.is_paid === 1 &&
        action.serverBasket.source_id !== 5 &&
        action.serverBasket.source_id !== 6 &&
        action.serverBasket.source_id !== 8
      ) {
        return {
          ...initialBasketData,
          restaurantID: action.serverBasket.restaurant_id
        };
      }

      let basketJSON = typeof action.serverBasket.basket === 'string' ? JSON.parse(action.serverBasket.basket) : action.serverBasket.basket;

      if (action.serverBasket.uuid !== null) {
        Cookies.set("basketUUID", action.serverBasket.uuid, { secure: true });
      }
      var address =
        basketJSON.deliveryAddress === null
          ? { id: null, isValid: false }
          : basketJSON.deliveryAddress;
      return {
        ...state,
        uuid: action.serverBasket.uuid,
        restaurantID: action.serverBasket.restaurant_id,
        items: basketJSON.items,
        setMeals: basketJSON.setMeals,
        itemsSubTotal: calcItemsSubTotal(basketJSON.items),
        setMealsSubTotal: calcSetMealsSubTotal(basketJSON.setMeals),
        deliveryCollectionState: basketJSON.deliveryCollectionState,
        deliveryAddress: address,
        deliveryCost: basketJSON.deliveryCost,
        serviceCharge: basketJSON.service_charge,
        bagCharge: basketJSON.bag_charge,
        kukdPointsToSpend: basketJSON.kukd_points_to_spend || 0,
        appliedPromos: basketJSON.applied_promos || [],
        appliedVoucher: basketJSON.applied_voucher || null,
        value: action.serverBasket.value || 0,
        feedAChildCharge: basketJSON.feed_a_child_charge || 0,
        feedAChildOptIn: basketJSON.feed_a_child_opt_in || 0,
        selectedTimeslot: basketJSON.selectedTimeslot || null,
        is_paid: action.serverBasket.is_paid || 0,
				payment_captured: action.serverBasket.payment_captured,
				chosenPaymentType: basketJSON.chosenPaymentType
      };

    case ADD_ITEM_TO_BASKET:
      sortIndividualItem(action.newItem);

      if (!action.newItem.hash) {
        action.newItem.hash = Utils.hash(action.newItem);
      }

      if (!action.storeDispatch)
        throw new Error("storeDispatch is missing from action");
      API.addItemToServerBasket(action.newItem, state, action.storeDispatch);

      // To not add to the basket until API has retrieved the server basket,
      // just uncomment 'return state'
      // return state
      let itemsAfterAdd = [...state.items, action.newItem];
      return {
        ...state,
        restaurantID: action.newItem.restaurant_id,
        items: itemsAfterAdd,
        itemsSubTotal: calcItemsSubTotal(itemsAfterAdd),
        updatingBasket: true
      };

    case REMOVE_ITEM_FROM_BASKET:
      if (!action.storeDispatch)
        throw new Error("storeDispatch is missing from action");
      API.removeItemFromServerBasket(
        action.itemData,
        state,
        action.storeDispatch
      );

      // To not update the basket until API has retrieved the server basket,
      // just uncomment 'return state'
      // return state;

      let itemsAfterRemove = [...state.items];

      // Reverse items so that last instance of a particular item is removed
      itemsAfterRemove = itemsAfterRemove.reverse();
      let itemIndexToRemove = -1;
      for (let i = 0; i < itemsAfterRemove.length; i++) {
        if (itemIndexToRemove !== -1) break;
        if (itemsAfterRemove[i].hash === action.itemData.hash) {
          itemIndexToRemove = i;
        }
      }

      // itemsAfterRemove = itemsAfterRemove.reverse();
      itemsAfterRemove.splice(itemIndexToRemove, 1);
      itemsAfterRemove = itemsAfterRemove.reverse();

      return {
        ...state,
        items: itemsAfterRemove,
        itemsSubTotal: calcItemsSubTotal(itemsAfterRemove),
        updatingBasket: true
      };

    case ADD_SET_MEAL_TO_BASKET:
      sortSetMeal(action.setMealData);

      action.setMealData.isEditing = true;

      if (!action.setMealData.hash) {
        action.setMealData.hash = Utils.hash(action.setMealData);
      }

      if (!action.storeDispatch)
        throw new Error("storeDispatch is missing from action");
      API.addSetMealToServerBasket(
        action.setMealData,
        state,
        action.restaurantID,
        action.storeDispatch
      );

      // To not add to the basket until API has retrieved the server basket,
      // just uncomment 'return state'
      // return state
      let setMealsAfterAdd = [...state.setMeals, action.setMealData];
      return {
        ...state,
        restaurantID: action.restaurantID,
        setMeals: setMealsAfterAdd,
        setMealsSubTotal: calcSetMealsSubTotal(setMealsAfterAdd),
        updatingBasket: true
      };

    case UPDATE_SET_MEAL_IN_BASKET:
      sortSetMeal(action.setMeal);

      let originalHash = action.setMeal.hash;
      delete action.setMeal.hash;
      action.setMeal.hash = Utils.hash(action.setMeal);

      if (!action.storeDispatch)
        throw new Error("storeDispatch is missing from action");
      API.updateSetMealInServerBasket(
        originalHash,
        action.setMeal,
        state,
        action.restaurantID,
        action.storeDispatch
      );

      // To not update the basket until API has retrieved the server basket,
      // just uncomment 'return state'
      // return state
      let setMealsAfterUpdate = [...state.setMeals];
      setMealsAfterUpdate.splice(
        setMealsAfterUpdate.indexOf(action.originalSetMeal),
        1,
        action.setMeal
      );
      return {
        ...state,
        setMeals: setMealsAfterUpdate,
        setMealsSubTotal: calcSetMealsSubTotal(setMealsAfterUpdate),
        updatingBasket: true
      };

    case REMOVE_SET_MEAL_FROM_BASKET:
      if (!action.storeDispatch)
        throw new Error("storeDispatch is missing from action");
      API.removeSetMealFromServerBasket(
        action.setMealData,
        state,
        action.restaurantID,
        action.storeDispatch
      );

      // To not remove from the basket until API has retrieved the server basket,
      // just uncomment 'return state'
      // return state
      let setMealsAfterRemove = [...state.setMeals];
      setMealsAfterRemove.splice(
        setMealsAfterRemove.indexOf(action.setMealData),
        1
      );
      return {
        ...state,
        setMeals: setMealsAfterRemove,
        setMealsSubTotal: calcSetMealsSubTotal(setMealsAfterRemove),
        updatingBasket: true
      };

    case SEND_SELECTED_ADDRESS_TO_SERVER:
      // GET DELIVERY COST BASED ON NEW ADDRESS
      // UPDATE SERVER WITH NEW ADDRESS AND DELIVERY COST
      API.updateDeliveryAddress(action.address, state, action.storeDispatch);
      API.updateDeliveryCost(action.deliveryCost, state, action.storeDispatch);

      return state;

    case UPDATE_DELIVERY_COST:
      return {
        ...state,
        deliveryCost: action.cost
      };

    case UPDATE_DELIVERY_COLLECTION_STATE:
      if (!action.storeDispatch)
        throw new Error("storeDispatch is missing from action");

      let updatedDeliveryCollectionState = {
        ...state.deliveryCollectionState,
        deliverySelected: action.deliverySelected,
        collectionSelected: action.collectionSelected
      };

      if (state.uuid) {
        API.sendDelColStateToServer(
          {
            ...state,
            deliveryCollectionState: updatedDeliveryCollectionState
          },
          action.storeDispatch
        );
        return {
          ...state,
          deliveryCollectionState: updatedDeliveryCollectionState,
          updatingBasket: true
        };
      } else {
        return {
          ...state,
          deliveryCollectionState: updatedDeliveryCollectionState
        };
      }

    case SET_UPDATING_BASKET:
      return {
        ...state,
        updatingBasket: action.val
      };

    case APPLY_KUKD_POINTS_TO_BASKET:
      API.applyKukdPoints(state.restaurantID, state.uuid, action.pointsToApply)
        .then(res => {
          action.storeDispatch(clearError());
          action.storeDispatch(setApplyingKukdPoints(false));

          switch (res.data.code) {
            case 200:
              action.storeDispatch(populateBasketFromServer(res.data.basket));
              break;
            case 401:
              action.storeDispatch(logoutUser());
              break;
            case 404:
              // SHOW SERVER ERROR RESPONSE MESSAGE TO USER
              action.storeDispatch(
                dispatchError(KukdError.KUKD_POINTS_ERROR, res.data.message)
              );
              break;

            default:
              break;
          }
        })
        .catch(err => {
          action.storeDispatch(setApplyingKukdPoints(false));
        });
      return state;

    case APPLY_VOUCHER_CODE_TO_BASKET:
      API.applyVoucherCode(action.voucherCode, state, action.storeDispatch);

      return state;

    case REMOVE_VOUCHER_CODE_FROM_BASKET:
      API.removeVoucherCode(state, action.storeDispatch);

      return {
        ...state,
        appliedVoucher: null
      };

    case APPLY_MANUAL_PROMO_TO_BASKET:
      API.applyManualPromo(action.promoID, state, action.storeDispatch);

      return state;

    case REMOVE_PROMO_FROM_BASKET:
      API.removePromoByID(action.promoID, state, action.storeDispatch);

      return state;

    case POPULATE_REORDER_DATA:
      return {
        ...state,
        uuid: null,
        restaurantID: action.orderData.restaurant_id,
        items: [],
        setMeals: [],
        deliveryCost: 0,
        itemsSubTotal: 0,
        setMealsSubTotal: 0
      };

    case SET_PAYMENT_TYPE:
      if (action.storeDispatch) {
        API.sendPaymentTypeToServerBasket(
          action.paymentType,
          state,
          action.storeDispatch
        );
      }
      return { ...state, updatingBasket: true };

    case UPDATE_FEED_A_CHILD_CHARGE:
      if (action.storeDispatch) {
        API.updateFeedAChildToServerBasket(
          action.feedAChildOptIn,
          state,
          action.storeDispatch
        );
        return { ...state, updatingBasket: true };
      } else {
        return state;
      }

    case DISPLAY_FULLSCREEN_BASKET:
      return { ...state, displayFullscreenBasket: action.display };

    default:
      return state;
  }
};

function sortSetMeal(setMeal) {
  for (let i = 0; i < setMeal.selections.length; i++) {
    sortSetMealItemsByID(setMeal.selections[i].chosenItems);
  }
  sortSetMealSelectionsByID(setMeal.selections);
}

function sortSetMealSelectionsByID(selections) {
  selections.sort((a, b) => {
    if (a.id < b.id) return -1;
    else if (a.id > b.id) return 1;
    else return 0;
  });
}
function sortSetMealItemsByID(items) {
  for (let i = 0; i < items.length; i++) {
    sortIndividualItem(items[i]);
  }
}

function sortIndividualItem(item) {
  if (!item.chosenSelections) return;

  // Sort extras inside each selection first
  for (let i = 0; i < item.chosenSelections.length; i++) {
    sortExtrasByID(item.chosenSelections[i].extras);
  }
  // Then sort each selection
  sortItemSelectionsByID(item.chosenSelections);
}
function sortItemSelectionsByID(selections) {
  selections.sort((a, b) => {
    if (a.selectionData.id < b.selectionData.id) return -1;
    else if (a.selectionData.id > b.selectionData.id) return 1;
    else return 0;
  });
}
function sortExtrasByID(extras) {
  extras.sort((a, b) => {
    if (a.extras.id < b.extras.id) return -1;
    else if (a.extras.id > b.extras.id) return 1;
    else return 0;
  });
}

function calcItemsSubTotal(items) {
  let subTotal = 0;
  items.map(item => {
    subTotal += calcSingleItemTotal(item);
  });
  return subTotal;
}

function calcSingleItemTotal(item) {
  let subTotal = 0;
  let extrasTotal = 0;
  subTotal += item.price;
  extrasTotal += calcItemSelectionsTotal(item.chosenSelections);
  return subTotal + extrasTotal;
}

function calcItemSelectionsTotal(selections) {
  let selectionsTotal = 0;
  if (selections) {
    selections.map(selection => {
      selectionsTotal += calcExtrasTotal(selection.extras);
    });
  }
  return selectionsTotal;
}

function calcExtrasTotal(extras) {
  let extrasTotal = 0;
  if (extras) {
    extras.map(extra => {
      extrasTotal += extra.price;
    });
  }
  return extrasTotal;
}

function calcSetMealsSubTotal(setMeals) {
  let subTotal = 0;
  let selectionsTotal = 0;
  let itemsTotal = 0;
  setMeals.map(setMeal => {
    subTotal += calcSingleSetMealTotal(setMeal);
  });
  return subTotal;
}

export function calcSingleSetMealTotal(setMeal) {
  let total = 0;
  let selectionsTotal = 0;
  let itemsTotal = 0;
  total += setMeal.price;
  setMeal.selections.map(selection => {
    selection.chosenItems.map(item => {
      let indexOfExcludedItem = selection.item_exclusions
        .split(",")
        .indexOf(item.id.toString());
      if (indexOfExcludedItem !== -1) {
        // This item is exluded. Apply charge for it.
        itemsTotal += selection.charge;
      } else {
        if (item.chosenSelections && item.chosenSelections.length) {
          itemsTotal += calcItemSelectionsTotal(item.chosenSelections);
        }
        // else {
        //   itemsTotal += calcSingleItemTotal(item);
        // }
      }
    });
  });
  return total + selectionsTotal + itemsTotal;
}

export default basketData;
