import { connect } from "react-redux";
import React from "react";
import API from "../../api";
import ReOrderEntry from "./ReOrderEntry";

class ReOrderBar extends React.Component {
  _isMounted = false;

  constructor({
    accountData // Redux Props
  }) {
    super(...arguments);

    this.state = {
      orders: [],
      pagination: {}
    };

    this.getOrderHistory = this.getOrderHistory.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.props.accountData.loggedIn) {
      this.getOrderHistory(1);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getOrderHistory(page = 1) {
    return API.getOrderHistory(page, 4)
      .then(res => {
        if (!this._isMounted) return;
        this.setState({
          ...this.state,
          orders: res.data.orders.data,
          pagination: res.data.orders.pagination
        });
      })
      .catch(err => {});
  }

  render() {
    if (!this.state.orders.length) return null;
    return (
      <>
      <div className="re-order-bar">
        <span className="d-flex flex-row justify-content-center">
          <h1 className="" style={{ zIndex: 1, color: '#fff', fontSize: '2em', fontWeight: 400, marginBottom: '30px', textShadow: '1px 1px 7px #000', textAlign: 'center'}}>My Previous Orders</h1>
        </span>
        <div className="order-list">
          {this.state.orders.map(orderData => {
            return <ReOrderEntry key={orderData.id} orderData={orderData} />;
          })}
        </div>
      </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    accountData: state.accountData
  };
};
export default connect(
  mapStateToProps,
  null
)(ReOrderBar);
