class AppRoutesClass {
  getAllRoutes() {
    return [
      { pageName: "index", href: "/", pretty: "Home" },
      { pageName: "home", href: "/", pretty: "Home" },
      { pageName: "login", href: "/login", pretty: "Login" },
      { pageName: "logout", href: "/logout", pretty: "Logout" },
      {
        pageName: "register",
        href: "/register",
        pretty: "Register",
        hideChildren: true
      },
      {
        pageName: "forgot-password",
        href: "/forgot-password",
        pretty: "Forgotten Password",
        parent: "login"
      },
      {
        pageName: "reset-password",
        href: "/reset-password",
        pretty: "Reset Password",
        parent: "login"
      },
      { pageName: "account", href: "/account", pretty: "Account" },
      {
        pageName: "info",
        href: "/account/info",
        pretty: "Personal Information"
      },
      {
        pageName: "address-book",
        href: "/account/address-book",
        pretty: "Address Book"
      },
      { pageName: "wallet", href: "/account/wallet", pretty: "Wallet" },
      {
        pageName: "orders",
        href: "/account/orders",
        pretty: "My Takeaway Orders"
      },
      {
        pageName: "reservations",
        href: "/account/reservations",
        pretty: "My Reservations"
      },
      {
        pageName: "password",
        href: "/account/password",
        pretty: "Change Password"
      },
      { pageName: "points", href: "/account/points", pretty: "Kukd Points" },
      {
        pageName: "referrals",
        href: "/account/referrals",
        pretty: "My Friend Referrals"
      },
      
      { pageName: "restaurants", href: "/restaurants", pretty: "Restaurants" },
      
      {
        pageName: "restaurant",
        href: "",
        pretty: "Restaurant",
        parent: "restaurants",
        hideInBreadcrumbs: true
      },
      { 
        pageName: "checkout", 
        href: "/checkout", 
        pretty: "Checkout" 
      },
      {
        pageName: "checkout-response",
        href: "/checkout-response",
        pretty: "Checkout Response"
      },
      {
        pageName: "checkout-authentication",
        href: "/checkout-authentication",
        pretty: "Checkout Authentication"
      },
      {
        pageName: "checkout-complete",
        href: "/checkout-complete",
        pretty: "Checkout Complete"
      },
      {
        pageName: "order-complete",
        href: "/checkout",
        pretty: "Order Complete"
      },
      {
        pageName: "order-view",
        href: "",
        pretty: "Order View",
        hideChildren: true
      },
      {
        pageName: "order-view-complete",
        href: "",
        pretty: "Order View",
        hideChildren: true
      },
      {
        pageName: "next-day-feedback",
        href: "",
        pretty: "Next Day Feedback",
        hideChildren: true
      },
      {
        pageName: "privacy-policy",
        href: "/privacy-policy",
        pretty: "Privacy Policy"
      },
      { pageName: "cookies", href: "/cookies", pretty: "Cookies" },
      { pageName: "mobile-apps", href: "/mobile-apps", pretty: "Mobile Apps" },

      { 
        pageName: "faq", 
        href: "/faq", 
        pretty: "Frequently Asked Questions" 
      },
      
      { 
        pageName: "topic",
        href: "/faq/topic",
        parent: "faq",
        hideInBreadcrumbs: true,
        pretty: "FAQ Topics"
      },
    

      { pageName: "about", href: "/about", pretty: "About Kukd.com" },
      { pageName: "contact", href: "/contact", pretty: "Contact Us" },
      { pageName: "kukd-points", href: "/kukd-points", pretty: "KUKD Points" },
      {
        pageName: "takeaway",
        href: "/takeaway",
        pretty: "All Takeaway Cuisines & Locations"
      },
      {
        pageName: "refer-a-friend",
        href: "/refer-a-friend",
        pretty: "Refer a Friend"
      },
      {
        pageName: "cancel-referral",
        href: "/cancel-referral",
        pretty: "Cancel Referral"
      },
      {
        pageName: "recommend-restaurant",
        href: "/recommend-restaurant",
        pretty: "Recommend A Restaurant"
      }
    ];
  }

  /**
   * Get the route based on a page name
   * @param {string} pageName The name of the page
   * @returns {Object} Route - {pageName, href, as}
   */
  getRoute(pageName) {
    let route = this.getAllRoutes().filter(route => {
      return route.pageName === pageName;
    })[0];
    return route || { pretty: pageName };
  }
}
const AppRoutes = new AppRoutesClass();
export default AppRoutes;
