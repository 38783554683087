import {
  SET_CHECKOUT_STEP,
  POPULATE_DELIVERY_ADDRESSES,
  SET_SELECTED_ADDRESS,
  POPULATE_PAYMENT_CARDS,
  SET_SELECTED_PAYMENT_CARD,
  SET_PAYMENT_TYPE,
  SET_BILLING_ADDRESS,
  UPDATE_MOBILE_VERIFICATION_REQUESTED,
  UPDATE_MOBILE_TO_VERIFY,
  UPDATE_MOBILE_IS_VERIFIED,
  UPDATE_VERIFICATION_CODE,
  UPDATE_PAYMENT_RESPONSE,
  SET_SELECTED_TIMESLOT,
  UPDATE_SPECIAL_INSTRUCTIONS,
  SET_FETCHING_KUKD_POINTS,
  SET_KUKD_POINTS_ENTERED,
  SET_APPLYING_KUKD_POINTS,
  POPULATE_BASKET_FROM_SERVER,
  SET_EDITING_ADDRESS,
  UPDATE_CARD_INFO
} from "../actions";
import API from "../api";

const checkoutData = (
  state = {
    currentStep: 0,
    selectedTimeslot: null,
    deliveryAddresses: [],
    paymentCards: [],
    chosenPaymentType: -1,
    specialInstructions: "",
    billingAddress: {
      houseNo: "",
      streetName: "",
      city: "",
      postcode: "",
      storeMyCard: false
    },
    mobileVerification: {
      mobileNo: "",
      verificationRequested: false,
      verificationCode: "",
      isVerified: false
    },
    kukdPoints: {
      isFetchingPoints: false,
      isApplyingPoints: false,
      pointsToApply: null
    },
    paymentResponse: {}
  },
  action
) => {
  switch (action.type) {
    case POPULATE_BASKET_FROM_SERVER:
      // Keep an eye on responses from basket api
      // If basket value becomes zero, paymentType will be 12.
      // We store this in checkoutData and update ux accordingly
      let chosenPaymentType = state.chosenPaymentType;
      let basketJSON = JSON.parse(action.serverBasket.basket);
      switch (basketJSON.chosenPaymentType) {
        case -1:
          // type has been reset
          chosenPaymentType = -1;
          break;
        case 1:
          // Cash
          chosenPaymentType = 2;
          break;
        // Commented to remove paypal
        // case 2:
        //   // Paypal
        //   chosenPaymentType = 3;
        //   break;
        case 3:
          // Bank
          if (state.chosenPaymentType !== 0 && state.chosenPaymentType !== 1) {
            chosenPaymentType = 0;
          }
          break;
        case 12:
          // Value of basket is zero, nothing to pay!
          chosenPaymentType = 12;
          break;

        default:
          break;
      }

      return {
        ...state,
        chosenPaymentType: chosenPaymentType
      };

    case SET_CHECKOUT_STEP:
      return {
        ...state,
        currentStep: action.stepNo
      };
    case SET_SELECTED_TIMESLOT:
      API.sendTimeSlotToServer(action.timeslot, action.basketData, action.storeDispatch)
      let timeSlot;
      if (action.timeslot === null) {
        timeSlot = null
      } else {
        timeSlot = {
          value: action.timeslot.value,
          display: action.timeslot.display || action.timeslot.pretty
        }
      }
      return {
        ...state,
        selectedTimeslot: timeSlot
      };
    case POPULATE_DELIVERY_ADDRESSES:
      // let addressesWithSelected = action.addresses.map(address => {
      //   address.selected = address.default_address;
      //   return address;
      // });

      return {
        ...state,
        deliveryAddresses: action.addresses
      };

    case SET_SELECTED_ADDRESS:
      let updatedAddressesSelected = [...state.deliveryAddresses].map(
        address => {
          if (address.id === action.address.id) {
            return { ...address, selected: true };
          } else {
            return { ...address, selected: false };
          }
        }
      );
      return {
        ...state,
        deliveryAddresses: updatedAddressesSelected
      };

    case SET_EDITING_ADDRESS:
      let updatedAddressesEditing = [...state.deliveryAddresses].map(
        address => {
          if (address.id === action.addressID) {
            return { ...address, editing: action.isEditing };
          } else {
            if (action.isEditing) {
              return { ...address, editing: false };
            } else {
              return { ...address };
            }
          }
        }
      );
      return {
        ...state,
        deliveryAddresses: updatedAddressesEditing
      };

    case POPULATE_PAYMENT_CARDS:
      let cardsWithSelected = action.cards.map((card, i) => {
        card.selected = i === 0;
        card.card_info = "";
        return card;
      });

      return {
        ...state,
        paymentCards: cardsWithSelected
      };

    case SET_SELECTED_PAYMENT_CARD:
      let updatedCards = [...state.paymentCards].map(card => {
        if (card.id === action.card.id) {
          return { ...card, selected: true };
        } else {
          return { ...card, selected: false };
        }
      });
      return {
        ...state,
        paymentCards: updatedCards
      };

    case UPDATE_CARD_INFO:
      let updatedInfoCards = [...state.paymentCards].map(
        card => (card.id === action.card.id ? action.card : card)
      );
      return {
        ...state,
        paymentCards: updatedInfoCards
      };

    case SET_PAYMENT_TYPE:
      return {
        ...state,
        chosenPaymentType: action.paymentType
      };

    case SET_BILLING_ADDRESS:
      let newBillingAddress = {
        ...state.billingAddress,
        ...action.billingAddress
      };
      return {
        ...state,
        billingAddress: newBillingAddress
      };

    case UPDATE_MOBILE_TO_VERIFY:
      return {
        ...state,
        mobileVerification: {
          ...state.mobileVerification,
          mobileNo: action.mobileNo
        }
      };

    case UPDATE_MOBILE_VERIFICATION_REQUESTED:
      return {
        ...state,
        mobileVerification: {
          ...state.mobileVerification,
          verificationRequested: action.verificationRequested
        }
      };

    case UPDATE_VERIFICATION_CODE:
      return {
        ...state,
        mobileVerification: {
          ...state.mobileVerification,
          verificationCode: action.verificationCode
        }
      };

    case UPDATE_MOBILE_IS_VERIFIED:
      return {
        ...state,
        mobileVerification: {
          ...state.mobileVerification,
          isVerified: action.isVerified
        }
      };
    case UPDATE_PAYMENT_RESPONSE:
      return {
        ...state,
        paymentResponse: action.data
      };
    case UPDATE_SPECIAL_INSTRUCTIONS:
      return {
        ...state,
        specialInstructions: action.specialInstructions
      };

    case SET_FETCHING_KUKD_POINTS:
      return {
        ...state,
        kukdPoints: {
          ...state.kukdPoints,
          isFetchingPoints: action.isFetchingPoints
        }
      };
    case SET_KUKD_POINTS_ENTERED:
      return {
        ...state,
        kukdPoints: {
          ...state.kukdPoints,
          pointsToApply: action.pointsToApply
        }
      };
    case SET_APPLYING_KUKD_POINTS:
      return {
        ...state,
        kukdPoints: {
          ...state.kukdPoints,
          isApplyingPoints: action.isApplyingPoints
        }
      };

    default:
      return state;
  }
};

export default checkoutData;
